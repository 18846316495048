/*
 * The syntax light mode code snippet colors.
 */

@mixin light-syntax {
  /* --- custom light colors --- */
  --language-border-color: #ececec;
  --highlight-bg-color: #f6f8fa;
  --highlighter-rouge-color: #3f596f;
  --highlight-lineno-color: #9e9e9e;
  --inline-code-bg: rgba(25, 25, 28, 0.05);
  --code-color: #3a3a3a;
  --code-header-text-color: #a3a3a3;
  --code-header-muted-color: #e5e5e5;
  --code-header-icon-color: #c9c8c8;
  --clipboard-checked-color: #43c743;

  /* --- Syntax highlight theme from `rougify style github` --- */

  .highlight table td {
    padding: 5px;
  }

  .highlight table pre {
    margin: 0;
  }

  .highlight,
  .highlight .w {
    color: #24292f;
    background-color: #f6f8fa;
  }

  .highlight .k,
  .highlight .kd,
  .highlight .kn,
  .highlight .kp,
  .highlight .kr,
  .highlight .kt,
  .highlight .kv {
    color: #cf222e;
  }

  .highlight .gr {
    color: #f6f8fa;
  }

  .highlight .gd {
    color: #82071e;
    background-color: #ffebe9;
  }

  .highlight .nb {
    color: #953800;
  }

  .highlight .nc {
    color: #953800;
  }

  .highlight .no {
    color: #953800;
  }

  .highlight .nn {
    color: #953800;
  }

  .highlight .sr {
    color: #116329;
  }

  .highlight .na {
    color: #116329;
  }

  .highlight .nt {
    color: #116329;
  }

  .highlight .gi {
    color: #116329;
    background-color: #dafbe1;
  }

  .highlight .kc {
    color: #0550ae;
  }

  .highlight .l,
  .highlight .ld,
  .highlight .m,
  .highlight .mb,
  .highlight .mf,
  .highlight .mh,
  .highlight .mi,
  .highlight .il,
  .highlight .mo,
  .highlight .mx {
    color: #0550ae;
  }

  .highlight .sb {
    color: #0550ae;
  }

  .highlight .bp {
    color: #0550ae;
  }

  .highlight .ne {
    color: #0550ae;
  }

  .highlight .nl {
    color: #0550ae;
  }

  .highlight .py {
    color: #0550ae;
  }

  .highlight .nv,
  .highlight .vc,
  .highlight .vg,
  .highlight .vi,
  .highlight .vm {
    color: #0550ae;
  }

  .highlight .o,
  .highlight .ow {
    color: #0550ae;
  }

  .highlight .gh {
    color: #0550ae;
    font-weight: bold;
  }

  .highlight .gu {
    color: #0550ae;
    font-weight: bold;
  }

  .highlight .s,
  .highlight .sa,
  .highlight .sc,
  .highlight .dl,
  .highlight .sd,
  .highlight .s2,
  .highlight .se,
  .highlight .sh,
  .highlight .sx,
  .highlight .s1,
  .highlight .ss {
    color: #0a3069;
  }

  .highlight .nd {
    color: #8250df;
  }

  .highlight .nf,
  .highlight .fm {
    color: #8250df;
  }

  .highlight .err {
    color: #f6f8fa;
    background-color: #82071e;
  }

  .highlight .c,
  .highlight .ch,
  .highlight .cd,
  .highlight .cm,
  .highlight .cp,
  .highlight .cpf,
  .highlight .c1,
  .highlight .cs {
    color: #68717a;
  }

  .highlight .gl {
    color: #68717a;
  }

  .highlight .gt {
    color: #68717a;
  }

  .highlight .ni {
    color: #24292f;
  }

  .highlight .si {
    color: #24292f;
  }

  .highlight .ge {
    color: #24292f;
    font-style: italic;
  }

  .highlight .gs {
    color: #24292f;
    font-weight: bold;
  }
} /* light-syntax */
